<template>
  <div class="tw-flex tw-justify-center">
    <div class="sculptor">
      <div class="top-section tw-flex tw-items-center">
        <p>Payments</p>
        <div class="search-id-div" style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)">
          <v-text-field placeholder="Search payment ID" color="#004aad" class="p-0 tf-styles" solo flat dense
            hide-details v-model="search">
            <template #prepend-inner>
              <icons class="mt-1 mr-2" name="atm" color="#939393" />
            </template>
          </v-text-field>
        </div>
      </div>

      <div class="tw-flex card-sculptors">
        <div class="total-unit">
          <div class="tw-flex tw-items-center">
            <icons name="atm" color="#1058bb" class="mr-2" />
            <span class="darker-text ml-1">Payment Logs</span>
            <v-spacer></v-spacer>
            <div class="tw-flex">
              <span class="lighter-text">Today</span>
              <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
            </div>
          </div>
          <p class="total-unit-figure mt-3">{{ totalpayment }}</p>
          <div class="tw-flex tw-justify-end">
            <icons name="temp-graph" color="#1058bb" />
          </div>
        </div>
        <div class="total-unit">
          <div class="tw-flex tw-items-center">
            <icons name="atm" color="#B41ECD" class="mr-2" />
            <span class="darker-text ml-1">All Payment References</span>
            <v-spacer></v-spacer>
            <div class="tw-flex">
              <span class="lighter-text">Today</span>
              <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
            </div>
          </div>
          <p class="total-unit-figure mt-3">{{ getTotalPaymentReferences }}</p>
          <div class="tw-flex tw-justify-end">
            <icons name="temp-graph" color="#B41ECD" />
          </div>
        </div>
        <div class="total-unit">
          <div class="tw-flex tw-items-center">
            <icons name="atm" color="#0F8463" class="mr-2" />
            <span class="darker-text ml-1">Sucessful</span>
            <v-spacer></v-spacer>
            <div class="tw-flex">
              <span class="lighter-text">Today</span>
              <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
            </div>
          </div>
          <p class="total-unit-figure mt-3">{{ getSuccessfulCount }}</p>
          <div class="tw-flex tw-justify-end">
            <icons name="temp-graph" color="#0F8463" />
          </div>
        </div>
        <div class="total-unit">
          <div class="tw-flex tw-items-center">
            <icons name="atm" color="#C84867" class="mr-2" />
            <span class="darker-text ml-1">Failed</span>
            <v-spacer></v-spacer>
            <div class="tw-flex">
              <span class="lighter-text">Today</span>
              <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
            </div>
          </div>
          <p class="total-unit-figure mt-3">{{ getFailedCount }}</p>
          <div class="tw-flex tw-justify-end">
            <icons name="temp-graph" color="#C84867" />
          </div>
        </div>
        <div class="categories-box">
          <div class="tw-flex tw-items-center">
            <span class="lighter-text">Complaints</span>
            <v-spacer></v-spacer>
            <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
          </div>
          <div class="tw-flex inner-sculptor">
            <v-col cols="6" sm="6" style="padding:5px">
              <p class="total-unit-figure mt-1 small-darker-text">100</p>
              <span class="status-tags" style="color:#0F674E">Active</span>
            </v-col>
            <v-col cols="6" sm="6" style="padding:5px">
              <p class="total-unit-figure mt-1 small-darker-text">10</p>
              <span class="status-tags" style="color:#71011C ">Resolved</span>
            </v-col>
          </div>
        </div>
      </div>
      <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
        <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
          <div class="mr-2 tw-mt-5 lg:tw-mt-0 laptop-view">
            <v-btn text class="header" @click="selectedFilter = 'all'"
              :color="selectedFilter === 'all' ? '#004aad' : '#939393'">
              <icons :color="selectedFilter === 'all' ? '#004aad' : '#939393'" class="mr-2" name="atm" />
              All Payment Log
            </v-btn>
            <v-btn text class="header" @click="selectedFilter = 'paystack'"
              :color="selectedFilter === 'paystack' ? '#004aad' : '#939393'">
              <icons :color="selectedFilter === 'paystack' ? '#004aad' : '#939393'" class="mr-2" name="atm" />
              All Payment References
            </v-btn>
            <v-btn text class="header" @click="selectedFilter = 'successful'"
              :color="selectedFilter === 'successful' ? '#004aad' : '#939393'">
              <icons :color="selectedFilter === 'successful' ? '#004aad' : '#939393'" class="mr-2" name="atm" />
              Successful
            </v-btn>
            <v-btn text class="header" @click="selectedFilter = 'failed'"
              :color="selectedFilter === 'failed' ? '#004aad' : '#939393'">
              <icons :color="selectedFilter === 'failed' ? '#004aad' : '#939393'" class="mr-2" name="atm" />
              Failed
            </v-btn>
          </div>
          <div class="mobile-view">
            <v-select :items="items" item-text="text" hide-details class="darker-text tf-styles" item-value="value" flat
              solo v-model="selectedFilter">
              <template #append>
                <icons name="select-dropdown" />
              </template>
            </v-select>
          </div>
          <div class="mr-2 search-div">
            <v-text-field placeholder="Search" color="#004aad" class="p-0 tf-styles" solo flat dense hide-details
              v-model="search" style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)">
              <template #prepend-inner>
                <icons class="mt-1 mr-1" name="search-icon" />
              </template>
            </v-text-field>
          </div>
          <v-data-table v-if="selectedFilter !== 'paystack'" :headers="headers" :mobile-breakpoint="0" :search="search"
            item-key="id" :single-select="false" height="available" :hide-default-footer="payment.length <= 7"
            :items="filteredPaymentLogs" :no-data-text="not_found" class="mt-4 mx-1 table" :loading="loading"
            @click:row="routeToViewPayment">
            <template #header.travellers>
              <span class="status">Full Name</span>
            </template>

            <template v-slot:[`item.travellers`]="{ item }">
              <span class="td-style">{{ getFullName(item.travellers) }}</span>
            </template>

            <template #header.paymentPurpose>
              <span class="status">Purpose</span>
            </template>

            <template v-slot:[`item.paymentPurpose`]="{ item }">
              <span class="td-style">{{ item.paymentPurpose }}</span>
            </template>

            <template #header.receivingAccountName>
              <span class="status">Account Name</span>
            </template>

            <template v-slot:[`item.receivingAccountName`]="{ item }">
              <span class="td-style">{{ item.receivingAccountName }}</span>
            </template>
            <template #header.receivingAccountNumber>
              <span class="status">Account Number</span>
            </template>

            <template v-slot:[`item.receivingAccountNumber`]="{ item }">
              <span class="td-style">{{ item.receivingAccountNumber }}</span>
            </template>
            <template #header.paymentReference>
              <span class="status">Payment ID</span>
            </template>
            <template v-slot:[`item.paymentReference`]="{ item }">
              <span class="td-style">{{ item.paymentReference }}</span>
            </template>
            <template #header.customerEmail>
              <span class="status">Email Address</span>
            </template>
            <template v-slot:[`item.customerEmail`]="{ item }">
              <span class="td-style" style="text-transform:lowercase">{{
                item.customerEmail
              }}</span>
            </template>
            <template #header.createdAt>
              <span class="status">Payment Time</span>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              <span class="td-style">{{ formatDateTime(item.createdAt) }}</span>
            </template>
            <template #header.amount>
              <span class="status">Amount</span>
            </template>

            <template v-slot:[`item.amount`]="{ item }">
              <span class="td-style">₦{{ formatNumber(item.amount) }}</span>
            </template>
            <template #header.discountedAmount>
              <span class="status">Discounted Amount</span>
            </template>

            <template v-slot:[`item.discountedAmount`]="{ item }">
              <span class="td-style">₦{{ formatNumber(item.discountedAmount) }}</span>
            </template>
            <template #header.paymentPurposeStatus>
              <span class="status">Status</span>
            </template>
            <template v-slot:[`item.paymentPurposeStatus`]="{ item }">
              <v-chip v-if="item.paymentPurposeStatus.toLowerCase() === 'successful'" color="#00C48C69">
                <span class="td-style" style="color: #0F674E">
                  Success
                </span>
              </v-chip>
              <v-chip v-if="item.paymentPurposeStatus.toLowerCase() === 'failed'" color="#C8486759">
                <span class="td-style" style="color: #71011C">
                  Failed
                </span>
              </v-chip>
            </template>
            <template #header.actions>
              <span class="status">Actions</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-end">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="color">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="" @click="routeToViewPayment(item)">
                    <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                      <icons name="view-paddler" class="mr-2" />
                      View payment
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="" v-show="
                      item.paymentPurposeStatus.toLowerCase() === 'failed'
                    " @click="routeToSelectSeat(item)">
                    <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                      <icons name="tools" class="mr-2" />
                      Rectify booking
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <v-data-table v-else :headers="paymentReferenceHeaders" :mobile-breakpoint="0" :search="search" item-key="id"
            :single-select="false" height="available" :hide-default-footer="paystackHistory.length <= 7"
            :items="filteredPaymentLogs" :no-data-text="not_found" class="mt-4 mx-1 table" :loading="loading"
            @click:row="routeToViewPaymentRef" >
            <template #header.amount>
              <span class="status">Amount</span>
            </template>

            <template v-slot:[`item.amount`]="{ item }">
              <span class="td-style">₦{{ formatNumber(item.amount / 100) }}</span>
            </template>
            <template #header.customer.email>
              <span class="status">Customer</span>
            </template>
            <template v-slot:[`item.customer.email`]="{ item }">
              <span class="td-style" style="text-transform:lowercase">{{
                item.customer.email
              }}</span>
            </template>
            <template #header.receivingAccountName>
              <span class="status">Account Name</span>
            </template>

            <template v-slot:[`item.receivingAccountName`]="{ item }">
              <span class="td-style">{{ item.receivingAccountName }}</span>
            </template>
            <template #header.subaccount.business_name>
              <span class="status">Account Number</span>
            </template>

            <template v-slot:[`item.subaccount.business_name`]="{ item }">
              <span class="td-style">{{ item.subaccount.business_name }}</span>
            </template>
            <template #header.reference>
              <span class="status">Payment ID</span>
            </template>
            <template v-slot:[`item.reference`]="{ item }">
              <span class="td-style">{{ item.reference }}</span>
            </template>

            <template #header.createdAt>
              <span class="status">Payment Time</span>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              <span class="td-style">{{ formatDateTime(item.createdAt) }}</span>
            </template>

            <template #header.status>
              <span class="status">Status</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status.toLowerCase() === 'success'" color="#00C48C69">
                <span class="td-style" style="color: #0F674E">
                  Success
                </span>
              </v-chip>
              <v-chip v-if="item.status.toLowerCase() === 'failed'" color="#C8486759">
                <span class="td-style" style="color: #71011C">
                  Failed
                </span>
              </v-chip>
              <v-chip v-if="item.status.toLowerCase() === 'abandoned'" color="rgba(147, 147, 147, 0.41)">
                <span class="td-style" style="color: #4B4B4B">
                  Abandoned
                </span>
              </v-chip>
            </template>
            <template #header.actions>
              <span class="status">Actions</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-end">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="color">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="" @click="routeToViewPaymentRef(item)">
                    <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                      <icons name="view-paddler" class="mr-2"  />
                      View reference
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="" v-show="item.status.toLowerCase() === 'failed'">
                    <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                      <icons name="tools" class="mr-2" />
                      Rectify
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters
  } from "vuex";
  import Icons from "../../components/reuseables/Icons.vue";
  import {
    getAllPaymentLogs,
    getPaymentReferences
  } from "../../services/api/APIService";
  // import Modal from "@/components/reuseables/Modal";
  // import BaseButton from "@/components/reuseables/BaseButton.vue";
  export default {
    name: "PaymentDashboard",
    components: {
      Icons
    },
    data() {
      return {
        color: "#004AAD",
        search: "",
        items: [{
            text: "All Payment Log",
            value: "all"
          },
          {
            text: "All Payment References",
            value: "paystack"
          },
          {
            text: "Successful",
            value: "successful"
          },
          {
            text: "Failed",
            value: "failed"
          }
        ],
        payment: [],
        not_found: "Units will show here",
        headers: [{
            text: "Full name",
            value: "travellers",
            sortable: false
          },
          {
            text: "Purpose",
            value: "paymentPurpose",
            sortable: false
          },
          {
            text: "Account Name",
            value: "receivingAccountName",
            sortable: false
          },
          {
            text: "Account No.",
            value: "receivingAccountNumber",
            sortable: false
          },
          {
            text: "Payment ID",
            value: "paymentReference",
            sortable: false
          },
          {
            text: "Email Address",
            value: "customerEmail",
            sortable: false
          },
          {
            text: "Payment time",
            value: "createdAt",
            sortable: false
          },
          {
            text: "Amount",
            value: "amount",
            sortable: false
          },
          {
            text: "discountedAmount",
            value: "discountedAmount",
            sortable: false
          },
          {
            text: "Status",
            value: "paymentPurposeStatus",
            sortable: false
          },
          {
            text: "",
            align: "end",
            value: "actions",
            sortable: false
          }
        ],
        paymentReferenceHeaders: [{
            text: "Amount",
            value: "amount",
            sortable: false
          },
          {
            text: "Customer",
            value: "customer.email",
            sortable: false
          },
          {
            text: "Payment ID",
            value: "reference",
            sortable: false
          },
          {
            text: "Account Name",
            value: "subaccount.business_name",
            sortable: false
          },
          {
            text: "Status",
            value: "status",
            sortable: false
          },
          {
            text: "Payment time",
            value: "createdAt",
            sortable: false
          },

          {
            text: "",
            align: "end",
            value: "actions",
            sortable: false
          }
        ],
        loading: false,
        totalpayment: 0,
        selectedFilter: "all",
        paystackHistory: ""
      };
    },
    methods: {
      formatDateTime(dateTime) {
        const options = {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          day: "numeric",
          month: "long",
          year: "numeric"
        };
        const formattedDateTime = new Date(dateTime).toLocaleString(
          "en-US",
          options
        );
        const formattedDateWithoutAt = formattedDateTime.replace(" at", ", ");
        return formattedDateWithoutAt;
      },
      getFirstLetter(firstname) {
        return firstname.charAt(0);
      },
      handleModal(item) {
        if (item) {
          this.selectedPost = item;
        }
        this.showModal = !this.showModal;
      },
      async getAllPaymentReferences() {
        this.loading = true;
        await getPaymentReferences()
          .then(res => {
            this.paystackHistory = res.data.data;
            this.loading = false;
          })
          .catch(error => {
            console.error("Error fetching payment references:", error);
          });
      },
      async getAllPaymentLogs() {
        this.loading = true;
        let data = {}
        data.page = 0
        data.pageSize= 1000
        await getAllPaymentLogs(data)
          .then(res => {
            this.payment = res.data;
            this.loading = false;
          })
          .catch(err => {
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false
            });
            this.loading = false;
          });
      },
      routeToViewPayment(item) {
        this.$store.dispatch("paymentLog/setSelectedPayment", item);
        this.$router.push({
          name: "ViewPayment"
        });
      },
       async routeToSelectSeat(item) {
          let data = {}
          data.companyName = item.receivingAccountName
          data.id = item.tripId
          await this.$store.dispatch("booking/setUserSelectedTrip", data);
        this.$store.dispatch("companyProfile/setCompanyData", data)
        sessionStorage.setItem("userSelectedPayment",JSON.stringify(item))
        this.$router.push({
          name: "OneWaySelectSeats"
        });
      },
      routeToViewPaymentRef(item) {
        this.$store.dispatch("paymentLog/setSelectedPayment", item);
        this.$router.push({
          name: "ViewPaymentRef"
        });
      },
      getFullName(travellers) {
        if (travellers.length) {
          return travellers[0].firstName + " " + travellers[0].lastName
        }
      },
      formatNumber(number){
        if(number){
          return number.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2})
        }
      },
    },
    watch: {
      getSearchedPaymentId(newVal) {
        this.search = newVal;
      }
    },
    async created() {
      await this.getAllPaymentLogs();
      await this.getAllPaymentReferences();
      this.totalpayment = this.payment.length;
      this.paymentId = this.$route.params.paymentId;
      if (this.getSearchedPaymentId) {
        this.search = this.getSearchedPaymentId;
      }
    },

    computed: {
      filteredPaymentLogs() {
        if (this.selectedFilter === "successful") {
          return this.payment.filter(
            item => item.paymentPurposeStatus.toLowerCase() === "successful"
          );
        } else if (this.selectedFilter === "failed") {
          return this.payment.filter(
            item => item.paymentPurposeStatus.toLowerCase() === "failed"
          );
        } else if (this.selectedFilter === "paystack") {
          return this.paystackHistory;
        } else {
          return this.payment;
        }
      },
      getSuccessfulCount() {
        if (this.payment) {
          return this.payment.filter(
            item => item.paymentPurposeStatus.toLowerCase() === "successful"
          ).length;
        } else {
          return 0;
        }
      },
      getFailedCount() {
        if (this.payment) {
          return this.payment.filter(
            item => item.paymentPurposeStatus.toLowerCase() === "failed"
          ).length;
        } else {
          return 0;
        }
      },
      getTotalPaymentReferences() {
        if (this.paystackHistory) {
          return this.paystackHistory.length;
        } else {
          return 0;
        }
      },
      ...mapGetters("paymentLog", ["getSearchedPaymentId"])
    }
  };
</script>

<style lang="scss" scoped>
  .sculptor {
    width: 100%;
  }

  .top-section {
    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: start;
    }
  }

  .top-section p {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }

  .laptop-view {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .mobile-view {
    display: none;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  .add-unit-btn {
    display: flex;
    color: #004aad;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    letter-spacing: 0px;
    padding: 12px;
    border-radius: 10px;
  }

  .add-unit-btn:hover {
    background-color: #c1bfbf4c;
  }

  .total-unit {
    width: 280px;
    height: 140px;
    border-radius: 10px;
    padding: 22px;
    background: #feffff;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);

    @media (max-width: 620px) {
      width: 100%;
    }
  }

  .categories-box {
    width: 130px;
    height: 150px;
    padding: 10px 15px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #feffff;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);

    @media (max-width: 620px) {
      height: fit-content;
      width: 240px;
    }
  }

  .search-div {
    width: 300px;

    .v-input {
      width: 100%;
    }

    @media screen and (max-width: 1264px) {
      width: 100%;
      margin-top: 15px;
    }
  }

  .search-id-div {
    width: 180px;
    margin-left: 15px;

    .v-input {
      width: 100%;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .inner-sculptor {
    flex-direction: column;

    @media (max-width: 620px) {
      flex-direction: row;
    }
  }

  .darker-text,
  .lighter-text {
    color: #263238;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 400;
    line-height: normal;
  }

  ::v-deep .tf-styles input::placeholder {
    color: #959595;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .lighter-text {
    color: #838383;
  }

  .total-unit-figure {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .small-darker-text {
    font-size: 16px;
  }

  .status-tags {
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .v-input {
    width: 200px;
  }

  .company-dashboard {
    display: flex;
    background: #ffffff;
    width: 100%;
    min-height: 70vh;
    max-height: available;
    border-radius: 10px;
    flex-direction: column;
  }

  .card-sculptors {
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 20px;

    @media (max-width: 620px) {
      flex-direction: column;
    }
  }

  .v-chip.v-size--default {
    width: 85px;
    display: flex;
    justify-content: center;
  }

  .header {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #939393;
    letter-spacing: 0px;
    text-transform: capitalize;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #7cb0f6;
    /* Set your desired background color here */
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: white;
  }

  .table {
    width: 100%;
  }

  .v-data-table__wrapper>table {
    margin-top: 55px !important;
  }

  .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th:first-child,
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
    padding-left: 16px;
  }

  .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
  .status {
    color: #939393;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
  .td-style {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
    text-transform: capitalize;
  }

  .company-logo {
    height: 100px;
    width: 100px;
    border-radius: 10px;
    object-fit: contain;
  }

  .no-image {
    display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-align: center;
  }
</style>